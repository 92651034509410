import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const OpeningTimes = props => {
	const { directMapsUrl, openingTimes, isOpen } = props
	return (
		<div className="OpeningTimes">
			<p>
				<FontAwesomeIcon className="has-text-success" icon="clock" />
				<strong>&nbsp;OPENING HOURS</strong>
			</p>
			<div className="status-box">
				<span className="status">
					{directMapsUrl ? (
						isOpen ? (
							<>
								<span>
									<FontAwesomeIcon className="has-text-success" icon="check" />
								</span>
								<span>&nbsp;STORE IS OPEN NOW</span>
							</>
						) : (
							<>
								<span>
									<FontAwesomeIcon className="has-text-danger" icon="times" />
								</span>
								<span>&nbsp; STORE IS CLOSED NOW</span>
							</>
						)
					) : (
						'COMMING SOON'
					)}
				</span>
			</div>
			<div className="times">
				{openingTimes.map((day, index) => (
					<span key={index}>{day}</span>
				))}
			</div>
		</div>
	)
}

OpeningTimes.propTypes = {
	directMapsUrl: PropTypes.string,
	openingTimes: PropTypes.array,
	isOpen: PropTypes.bool,
}

export default OpeningTimes
