import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const GoogleReviews = props => {
	const filteredGoogleReviews = []
	props.reviews.map(review => {
		// filter displayable reviews by content/rating
		if (review.text && review.rating >= 4) {
			filteredGoogleReviews.push(review)
		}
	})

	const FilteredReviews = () => {
		return (
			<div className="GoogleReviews">
				{filteredGoogleReviews.map((review, index) => (
					<article key={index} className="media">
						<a
							href={review.author_url}
							title="View user review on Google Maps"
							target="_blank"
							rel="nofollow noopener noreferrer"
							className="author"
						>
							<figure className="media-left">
								<p className="image is-64x64">
									<img src={review.profile_photo_url} />
								</p>
							</figure>
						</a>
						<div className="media-content">
							<div className="content">
								<strong>
									<a
										href={review.author_url}
										title="View user review on Google Maps"
										target="_blank"
										rel="nofollow noopener noreferrer"
										className="author"
									>
										{review.author_name}
									</a>
								</strong>
								<br />
								<span className="has-text-warning">
									<FontAwesomeIcon icon="star" />
									<FontAwesomeIcon icon="star" />
									<FontAwesomeIcon icon="star" />
									<FontAwesomeIcon icon="star" />
									{review.rating > 4.9 ? (
										<FontAwesomeIcon icon="star" />
									) : (
										<FontAwesomeIcon icon="star-half" />
									)}
								</span>
								&nbsp;
								<small className="tag">
									{review.relative_time_description}
								</small>
								<p>
									<span>
										<FontAwesomeIcon icon="quote-left" />
									</span>
									{review.text}
									<span>
										<FontAwesomeIcon icon="quote-right" />
									</span>
								</p>
							</div>
							<nav className="level is-mobile">
								<div className="level-left">
									<a className="level-item">
										<span className="icon is-small">
											<FontAwesomeIcon icon="thumbs-up" />
										</span>
										<span>&nbsp; Like</span>
									</a>
									<a className="level-item">
										<span className="icon is-small">
											<FontAwesomeIcon icon="share-alt" />
										</span>
										<span>&nbsp; Share</span>
									</a>
								</div>
							</nav>
						</div>
					</article>
				))}
			</div>
		)
	}
	return FilteredReviews()
}

GoogleReviews.propTypes = {
	shopName: PropTypes.string,
	reviews: PropTypes.array,
	rating: PropTypes.any,
}

export default GoogleReviews
