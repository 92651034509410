import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const MyStyles = {
	control: styles => ({ ...styles, backgroundColor: 'white' }),
	option: (styles, { isDisabled, isFocused, isSelected }) => {
		const dark = '#353e47'
		const blue = '#58c8df'
		return {
			...styles,
			backgroundColor: isDisabled
				? null
				: isSelected
					? blue
					: isFocused
						? dark
						: null,
			color: isDisabled
				? '#ccc'
				: isSelected
					? blue
						? 'white'
						: 'black'
					: blue,
			cursor: isDisabled ? 'not-allowed' : 'default',
		}
	},
	multiValue: styles => {
		return {
			...styles,
			backgroundColor: '#353e47',
		}
	},
	multiValueLabel: styles => ({
		...styles,
		color: '#58c8df',
	}),
	multiValueRemove: styles => ({
		...styles,
		color: '#58c8df',
		':hover': {
			backgroundColor: '#353e47',
			color: 'white',
		},
	}),
}

const MySelect = props => {
	const { options, errors, touched, onChange, onBlur, identity, multi } = props
	const handleChange = value => {
		// this is going to call setFieldValue and manually update values.topcis
		onChange(identity, value)
	}

	const handleBlur = () => {
		// this is going to call setFieldTouched and manually update touched.topcis
		onBlur(identity, true)
	}
	return (
		<>
			<Select
				id={identity + '-select'}
				options={options}
				isMulti={multi}
				closeMenuOnSelect={multi ? false : true}
				onChange={handleChange}
				onBlur={handleBlur}
				value={props.value}
				styles={MyStyles}
			/>
			{touched &&
				errors && (
					<p className="help is-danger" id={`${identity}-helper-text`}>
						{errors}
					</p>
				)}
		</>
	)
}

MySelect.propTypes = {
	multi: PropTypes.bool,
	value: PropTypes.any,
	options: PropTypes.any,
	errors: PropTypes.any,
	onChange: PropTypes.any,
	onBlur: PropTypes.any,
	touched: PropTypes.any,
	identity: PropTypes.any,
}

export default MySelect
