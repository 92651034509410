import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ImgLightBox = props => {
	const {
		imgUrl,
		largeImgUrl,
		googlePhoto,
		viewImage,
		imgIndex,
		selectedImage,
	} = props
	let { imgAlt, imgTitle, spanText } = props
	if (googlePhoto) {
		imgAlt = ''
		imgTitle = ''
		spanText = ''
	}
	if (imgUrl && largeImgUrl) {
		return (
			<div className="ImgLightBox">
				<div className="box">
					<a
						onClick={() => {
							viewImage(), selectedImage(imgIndex)
						}}
					>
						<div className="img-container">
							<img src={imgUrl} alt={imgAlt} title={imgTitle} />
							<div className="view">
								<FontAwesomeIcon icon="search" />
							</div>
						</div>
						<label className="label">
							<span className="icon">
								<FontAwesomeIcon icon="camera" />
							</span>
							<span>{spanText}</span>
						</label>
					</a>
				</div>
			</div>
		)
	} else {
		return ''
	}
}

ImgLightBox.propTypes = {
	imgIndex: PropTypes.number,
	viewImage: PropTypes.func,
	selectedImage: PropTypes.func,
	imgUrl: PropTypes.any,
	imgAlt: PropTypes.any,
	imgTitle: PropTypes.any,
	largeImgUrl: PropTypes.any,
	googlePhoto: PropTypes.any,
	spanText: PropTypes.any,
}
export default ImgLightBox
