import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import m500 from '../../img/m500.jpg'
import DolphinBrand from '../../img/dolphin.svg'

const CallToAction = props => {
	const { heading, text, openForm, ecommerceStoreLink, storeCountry } = props
	return (
		<div className="CallToAction">
			<div className="box">
				<div
					className="background"
					style={{ backgroundImage: `url(${m500})` }}
				/>
				<div className="gradient" />
				<article className="media">
					<div className="media-left is-hidden-mobile">
						<figure className="image is-128x128">
							<img src={DolphinBrand} />
						</figure>
					</div>
					<div className="media-content">
						<div className="content">
							<h3>{heading}</h3>
							<p>{text}</p>
							<div className="buttons">
								<button
									onClick={openForm}
									className="button is-dark is-outlined"
								>
									<span className="icon">
										<FontAwesomeIcon icon="comment" />
									</span>
									<span>Free Demo</span>
								</button>
								<button onClick={openForm} className="button is-warning">
									<span className="icon">
										<FontAwesomeIcon icon="info-circle" />
									</span>
									<span>More Info</span>
								</button>
							</div>
						</div>
					</div>
				</article>
			</div>
		</div>
	)
}

CallToAction.propTypes = {
	heading: PropTypes.string,
	text: PropTypes.string,
	openForm: PropTypes.func,
	ecommerceStoreLink: PropTypes.string,
	storeCountry: PropTypes.string,
}

export default CallToAction
