import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import { Formik } from 'formik'
import MySelect from './MySelect'

function encode(data) {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&')
}

function validationIcons(valid) {
	return valid ? (
		<span className="icon is-small is-right">
			<FontAwesomeIcon icon="exclamation-triangle" />
		</span>
	) : (
		<span className="icon is-small is-right">
			<FontAwesomeIcon icon="check" />
		</span>
	)
}

const EnquiryForm = props => {
	const {
		modalState,
		formName,
		openForm,
		shopName,
		shopEmail,
		storeCountry,
		regionOptions,
		relativeUrl,
	} = props
	return (
		<div
			className={modalState ? `is-active modal` : `modal`}
			id={`form-is-${formName}`}
		>
			<div className="modal-background" onClick={() => openForm('')} />
			<div className="modal-content">
				<div className="box">
					<div className="content">
						<h1 className="title">
							<span className="icon">
								<FontAwesomeIcon icon="comment" />
							</span>{' '}
							More Info
						</h1>
						<h2 className="subtitle">Contact us to get more information</h2>
						<Formik
							initialValues={{
								name: '',
								email: '',
								mobile: '',
								region: '',
								message: '',
							}}
							validate={values => {
								const errors = {}
								// * Full Name *REQUIRED
								if (!values.name) {
									errors.name = 'Please provide your full name.'
								}
								// * Email *REQUIRED
								if (!values.email) {
									errors.email = 'Please provide an email address.'
								} else if (
									!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(
										values.email
									)
								) {
									errors.email = 'Invalid email address.'
								}
								// * Mobile *REQUIRED
								if (!values.phone) {
									errors.phone = 'Please provide a phone number.'
								}
								// * Region *REQUIRED
								if (!values.region) {
									errors.region = 'Please provide your region.'
								}
								return errors
							}}
							onSubmit={(
								values,
								{ setSubmitting, setErrors, setStatus, setValues, setTouched }
							) => {
								const payload = {
									'form-name': 'more-info',
									name: values.name,
									email: values.email,
									phone: values.phone,
									region: values.region.value.toString(),
									message: values.message,
									shop_name: shopName,
									shop_email: shopEmail,
								}
								setSubmitting(true),
									fetch('/', {
										method: 'POST',
										headers: {
											'Content-Type': 'application/x-www-form-urlencoded',
										},
										body: encode(payload),
									}).then(
										res => {
											if (res.status === 200) {
												return (
													setStatus('success'),
													setSubmitting(false),
													setValues({
														name: '',
														email: '',
														phone: '',
														region: '',
														message: '',
													}),
													setErrors(''),
													setTouched(''),
													navigate(`/${relativeUrl}/?form=more-info`)
												)
											}
											if (res.status !== 200) {
												return setStatus('failure'), setSubmitting(false)
											}
											return setSubmitting(false)
										},
										errors => {
											setSubmitting(false)
											setErrors(errors)
										}
									)
							}}
							render={({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setStatus,
								status,
								setFieldValue,
								setFieldTouched,
							}) => (
								<form
									name="free-demo"
									method="post"
									data-netlify="true"
									data-netlify-honeypot="bot-field"
									onSubmit={handleSubmit}
									className="form"
								>
									<div className="field is-horizontal">
										<div className="field-label">
											<label className="label" htmlFor="name">
												Full Name
											</label>
										</div>
										<div className="field-body">
											<div
												className="field is-expanded"
												aria-describedby="name"
											>
												<div className="control has-icons-left has-icons-right">
													<input
														id="name"
														name="name"
														type="text"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.name}
														placeholder="John Smith"
														className={classNames(
															touched.name && errors.name && `is-danger`,
															values.name && !errors.name && `is-success`,
															`input`
														)}
													/>
													<span className="icon is-small is-left">
														<FontAwesomeIcon icon="user" />
													</span>
													{touched.name && errors.name && validationIcons(true)}
													{values.name &&
														!errors.name &&
														validationIcons(false)}
												</div>
												{touched.name && errors.name && (
													<p className="help is-danger" id="name-helper-text">
														{errors.name}
													</p>
												)}
											</div>
										</div>
									</div>
									<div className="field is-horizontal">
										<div className="field-label">
											<label className="label" htmlFor="email">
												Email
											</label>
										</div>
										<div className="field-body">
											<div
												className="field is-expanded"
												aria-describedby="email"
											>
												<div className="control has-icons-left has-icons-right">
													<input
														id="email"
														name="email"
														type="email"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.email}
														placeholder="you@example.com"
														className={classNames(
															touched.email && errors.email && `is-danger`,
															values.email && !errors.email && `is-success`,
															`input`
														)}
													/>
													<span className="icon is-small is-left">
														<FontAwesomeIcon icon="envelope" />
													</span>
													{touched.email &&
														errors.email &&
														validationIcons(true)}
													{values.email &&
														!errors.email &&
														validationIcons(false)}
												</div>
												{touched.email && errors.email && (
													<p className="help is-danger" id="email-helper-text">
														{errors.email}
													</p>
												)}
											</div>
										</div>
									</div>
									<div className="field is-horizontal">
										<div className="field-label">
											<label className="label" htmlFor="phone">
												Phone
											</label>
										</div>
										<div className="field-body">
											<div
												className="field is-expanded"
												aria-describedby="phone"
											>
												<div className="control has-icons-left has-icons-right">
													<input
														id="phone"
														name="phone"
														type="tel"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.phone}
														placeholder="Phone"
														className={classNames(
															touched.phone && errors.phone && `is-danger`,
															values.phone && !errors.phone && `is-success`,
															`input`
														)}
													/>
													<span className="icon is-small is-left">
														<FontAwesomeIcon icon="phone" />
													</span>
													{touched.phone &&
														errors.phone &&
														validationIcons(true)}
													{values.phone &&
														!errors.phone &&
														validationIcons(false)}
												</div>
												{touched.phone && errors.phone && (
													<p className="help is-danger" id="phone-helper-text">
														{errors.phone}
													</p>
												)}
											</div>
										</div>
									</div>
									<div className="field is-horizontal">
										<div className="field-label">
											<label className="label" htmlFor="region">
												Region
											</label>
										</div>
										<div className="field-body">
											<div
												className="field is-expanded"
												aria-describedby="region"
											>
												<div className="control">
													<MySelect
														identity={'region'}
														options={regionOptions}
														value={values.region}
														onChange={setFieldValue}
														onBlur={setFieldTouched}
														errors={errors.region}
														touched={touched.region}
														multi={false}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="field is-horizontal">
										<div className="field-label">
											<label className="label" htmlFor="message">
												Message
											</label>
										</div>
										<div className="field-body">
											<div
												className="field is-expanded"
												aria-describedby="message"
											>
												<div className="control has-icons-right">
													<textarea
														id="message"
														name="message"
														type="tel"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.message}
														placeholder="Your message..."
														className={classNames(
															touched.message && errors.message && `is-danger`,
															values.message && !errors.message && `is-success`,
															`textarea`
														)}
													/>
													{touched.message &&
														errors.message &&
														validationIcons(true)}
													{values.message &&
														!errors.message &&
														validationIcons(false)}
												</div>
												{touched.message && errors.message && (
													<p
														className="help is-danger"
														id="message-helper-text"
													>
														{errors.message}
													</p>
												)}
											</div>
										</div>
									</div>
									<p className="has-text-centered">
										<button
											type="submit"
											disabled={isSubmitting}
											className={classNames(
												isSubmitting && `is-loading`,
												`button is-secondary is-medium`
											)}
										>
											Submit
										</button>
									</p>
									<div
										className={
											status === 'success' ? `is-active modal` : `modal`
										}
										id="form-success"
									>
										<div
											className="modal-background"
											onClick={() => {
												setStatus(''), openForm('')
											}}
										>
											<div className="before" />
											<div className="after" />
										</div>
										<div className="modal-content">
											<div className="box">
												<div className="content">
													<h1>We have recieved your enquiry 😄</h1>
													<p>
														Thanks for taking the time to contact us.
														<br />
														We will be in touch soon.
													</p>
												</div>
											</div>
										</div>
										<button
											className="modal-close is-large"
											aria-label="close"
											onClick={() => setStatus('')}
										/>
									</div>
									<div
										className={
											status === 'failure' ? `is-active modal` : `modal`
										}
										id="form-failure"
									>
										<div
											className="modal-background"
											onClick={() => setStatus('')}
										/>
										<div className="modal-content">
											<div className="box">
												<div className="content">
													<h1>Something went wrong 😞</h1>
													<p>
														Form is invalid, please check your details and try
														again.
													</p>
												</div>
											</div>
										</div>
										<button
											className="modal-close is-large"
											aria-label="close"
											onClick={() => setStatus('')}
										/>
									</div>
								</form>
							)}
						/>
					</div>
				</div>
			</div>
			<button
				className="modal-close is-large"
				aria-label="close"
				onClick={() => openForm('')}
			/>
		</div>
	)
}

EnquiryForm.propTypes = {
	modalState: PropTypes.bool,
	formName: PropTypes.string,
	openForm: PropTypes.func,
	shopName: PropTypes.string,
	shopEmail: PropTypes.string,
	storeCountry: PropTypes.string,
	modelOptions: PropTypes.array,
	regionOptions: PropTypes.array,
	conditionOptions: PropTypes.array,
	relativeUrl: PropTypes.string,
}

export default EnquiryForm
