import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DolphinService from '../../img/dolphin-service.jpg'

const RepairAgent = props => {
	return (
		<div className="RepairAgent">
			<h3>
				<FontAwesomeIcon icon="wrench" /> Dolphin Robot Service &amp; Repair
			</h3>
			<div className="box">
				<div
					className="background"
					style={{ backgroundImage: `url(${DolphinService})` }}
				/>
				<div className="gradient" />
				<img
					className="repairImg"
					src={DolphinService}
					alt="Dolphin service and repair"
				/>
				<div className="content">
					Service and
					<br />
					<b>Repair</b>
				</div>
			</div>
			<p>
				{props.shopName} is a professional Dolphin service agent, authorised and
				trained by Maytronics to undertake a full range of services, maintenance
				and repairs.
			</p>
			<p>
				With full diagnostic equipment, tools and carrying a wide range of
				parts, {props.shopName} is able to service your Dolphin robotic pool
				cleaner quickly, generally within 2-5 working days.
			</p>
		</div>
	)
}

RepairAgent.propTypes = {
	shopName: PropTypes.string,
}

export default RepairAgent
