import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SquareMapLink = props => {
	const { staticMapImg, shopName, directMapsUrl } = props
	const ViewOnMapLink = shopName
		? `View ${shopName} on Google Maps`
		: 'View on Google Maps'
	return (
		<div className="SquareMapLink">
			<div className="box">
				<a
					href={directMapsUrl}
					target="_blank"
					title={ViewOnMapLink}
					rel="nofollow noreferrer noopener"
					className="outer"
				>
					<div className="img-container">
						<figure
							className="image is-square"
							style={{ backgroundImage: `url(${staticMapImg})` }}
						>
							<img src={staticMapImg} />
						</figure>
						<div className="view">
							<FontAwesomeIcon icon="search" />
						</div>
					</div>
				</a>
			</div>
		</div>
	)
}

SquareMapLink.propTypes = {
	staticMapImg: PropTypes.string,
	shopName: PropTypes.string,
	directMapsUrl: PropTypes.string,
}

export default SquareMapLink
