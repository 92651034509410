import React from 'react'
import PropTypes from 'prop-types'

const HorizontalDivide = props => {
	return (
		<div className="HorizontalDivide">
			<nav className="level">
				<div className="level-item has-text-centered">
					<div>
						<img src={props.image} />
					</div>
				</div>
			</nav>
		</div>
	)
}

HorizontalDivide.propTypes = {
	image: PropTypes.string,
}

export default HorizontalDivide
