import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Arrow = props => {
	const { className, onClick, icon } = props
	return (
		<button className={`${className} button is-primary`} onClick={onClick}>
			<span className="icon is-small">
				<FontAwesomeIcon icon={icon} />
			</span>
		</button>
	)
}
const Dots = i => (
	<span className="fa-layers fa-fw">
		<FontAwesomeIcon icon="circle" />
		<span className="fa-layers-text fa-inverse">{i + 1}</span>
	</span>
)

const SlideShow = props => {
	const { slides, shopName } = props
	const settings = {
		dots: true,
		nextArrow: <Arrow icon="chevron-right" />,
		prevArrow: <Arrow icon="chevron-left" />,
		customPaging: Dots,
	}
	return (
		<div className="SlideShow">
			<h3>
				<FontAwesomeIcon icon="camera" /> Photos from {shopName}
			</h3>
			<Slider {...settings}>
				{slides.map((slide, index) => (
					<div key={index}>
						<img src={slide} />
					</div>
				))}
			</Slider>
		</div>
	)
}

Arrow.propTypes = {
	className: PropTypes.any,
	onClick: PropTypes.func,
	icon: PropTypes.string,
}

SlideShow.propTypes = {
	slides: PropTypes.array,
	shopName: PropTypes.string,
}

export default SlideShow
