import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import loadGoogleMapsApi from 'load-google-maps-api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from './layout.js'

// import AngledBanner from './AngledBanner'
import CallToAction from './CallToAction'
import EnquiryForm from './EnquiryForm'
import Nav from './Nav'
import Banner from './Banner'
import HorizontalDivide from './HorizontalDivide'
import ImgLightBox from './ImgLightBox'
import LightBox from './LightBox'
import OpeningTimes from './OpeningTimes'
import RatingStars from './RatingStars'
import RepairAgent from './RepairAgent'
import GoogleReviews from './GoogleReviews'
import SquareMapLink from './SquareMapLink'
import ProductStockist from './ProductStockist'
import Facebook from './Facebook'
import SlideShow from './SlideShow'

import divideSwash from '../img/divide-swash.png'
import divideS300i from '../img/divide-s-series.png'

class StorePage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
			isImageOpen: false,
			modalState: false,
			formName: '',
			imageIndex: '',
			formattedPhone: '',
			internationalPhone: '',
			formattedAddress: '',
			streetName: '',
			regionName: '',
			localityName: '',
			countryShortcode: '',
			photoUrls: [],
			openingTimes: [],
			starRating: '',
			amountRatings: '',
			userReviews: [],
			directMapsUrl: '',
		}
		this.callbackMapsLoaded = this.callbackMapsLoaded.bind(this)
		this.viewImage = this.viewImage.bind(this)
		this.selectedImage = this.selectedImage.bind(this)
		// this.openForm = this.openForm.bind(this)
	}

	componentDidMount() {
		const options = {
			key: `${process.env.GATSBY_GOOGLE_API}`,
			libraries: ['places'],
		}
		loadGoogleMapsApi(options)
			.then(googleMaps => {
				const newPlaceId = this.props.data.contentfulStore.placeId
				const request = { placeId: newPlaceId }
				return new google.maps.places.PlacesService(
					document.createElement('div')
				).getDetails(request, (place, status) =>
					this.callbackMapsLoaded(place, status)
				)
			})
			.catch(function(error) {
				console.error(error)
			})
	}

	callbackMapsLoaded(place, status) {
		if (status === 'OK') {
			// find if locality exists in google array
			// and return the index
			let hasLocality = false
			let indexOfLocality
			place.address_components.map((line, index) => {
				line.types.map(addressLineType => {
					if (addressLineType.includes('locality')) {
						indexOfLocality = index
						hasLocality = true
						return true
					}
					return false
				})
				return true
			})

			if (hasLocality) {
				this.setState({
					localityName: place.address_components[indexOfLocality].short_name,
				})
			}
			this.setState({
				isOpen: place.opening_hours.open_now,
				formattedPhone: place.formatted_phone_number,
				internationalPhone: place.international_phone_number,
				formattedAddress: place.formatted_address,
				streetName: place.address_components[1].short_name,
				regionName: place.vicinity,
				countryShortcode: place.address_components[4].short_name,
				starRating: place.rating,
				amountRatings: place.reviews.length,
				userReviews: place.reviews,
				directMapsUrl: place.url,
			})
			// * left sidebar photos from google
			if (place.photos) {
				place.photos.map(photo => {
					const photoUrl = photo.getUrl({
						maxWidth: 1000,
						maxHeight: 1000,
					})
					if (this.state.photoUrls.length < 8) {
						const photoUrlList = [...this.state.photoUrls, photoUrl]
						this.setState({
							photoUrls: [...photoUrlList],
						})
					}
					return true
				})
			}
			// * right sidebar opening times
			place.opening_hours.weekday_text.map(day => {
				const listOfDays = [...this.state.openingTimes, day]
				this.setState({
					openingTimes: [...listOfDays],
				})
				return true
			})
		} else {
			console.log('did not load place')
		}
	}

	viewImage() {
		this.setState(prevState => ({
			isImageOpen: !prevState.isImageOpen,
		}))
	}
	selectedImage(index) {
		this.setState({ imageIndex: index })
	}
	openForm = name => {
		this.setState(prevState => ({
			modalState: !prevState.modalState,
		}))
		this.setState({
			formName: name,
		})
	}

	render() {
		// * all single nodes from Contentful
		const {
			shopWebsite,
			shopAffiliate,
			relativeUrl,
			shopName,
			storeCountry,
			placeId,
			shopEmail,
			shopPageTitle,
			storePhotoImgAltText,
			storePhotoImgSpanText,
			storePhotoImgTitle,
			teamPhotoImgAltText,
			teamPhotoImgTitle,
			teamPhotoImgSpanText,
			facebookPageUrl,
			storeLogo,
			storePhoto,
			teamPhoto,
			staticMapUrl,
			mainPageTextContent,
		} = this.props.data.contentfulStore

		const {
			isOpen,
			modalState,
			formName,
			isImageOpen,
			imageIndex,
			formattedPhone,
			// internationalPhone,
			// formattedAddress,
			// streetName,
			regionName,
			localityName,
			// countryShortcode,
			photoUrls,
			openingTimes,
			starRating,
			amountRatings,
			userReviews,
			directMapsUrl,
		} = this.state

		const openForm = this.openForm

		let ecommerceStoreLink
		if (storeCountry === 'AU') {
			ecommerceStoreLink = 'https://mydolphin.com.au/pool-cleaners/'
		} else if (storeCountry === 'NZ') {
			ecommerceStoreLink = 'https://mydolphin.co.nz/pool-cleaners/'
		} else if (storeCountry === 'ZA') {
			ecommerceStoreLink = 'https://mydolphin.co.za/pool-cleaners/'
		}
		let canonicalRef
		if (storeCountry === 'AU') {
			canonicalRef = 'stores.mydolphin.com.au'
		} else if (storeCountry === 'NZ') {
			canonicalRef = 'stores.mydolphin.co.nz'
		} else if (storeCountry === 'ZA') {
			canonicalRef = 'stores.mydolphin.co.za'
		}
		let countryFull
		if (storeCountry === 'AU') {
			countryFull = 'Australia'
		} else if (storeCountry === 'NZ') {
			countryFull = 'New Zealand'
		} else if (storeCountry === 'ZA') {
			countryFull = 'South Africa'
		}

		// * set page text
		const pageText = mainPageTextContent.childMarkdownRemark.html
		return (
			<Layout>
				<Helmet>
					<meta charSet="utf-8" />
					<title>
						{`${shopName} - Dolphin Robotic Pool Cleaner ${
							localityName ? `${localityName}, ` : ''
						}${countryFull}`}
					</title>
					<meta
						name="description"
						content={`${shopName} are your local Dolphin by Maytronics experts, buy and service Dolphin Robotic Pool Cleaners in ${
							localityName ? `${localityName}, ` : ''
						}${countryFull}.${
							formattedPhone ? ` Call us on ${formattedPhone}` : ''
						}`}
					/>
					<meta
						name="keywords"
						content="dolphin, robotic, pool, cleaner, robot, cleaning, maytronics, swimming pool"
					/>
					<link
						rel="canonical"
						href={`https://${canonicalRef}/${relativeUrl}/`}
					/>
					<meta name="twitter:site" content="@MaytronicsAu" />
					<meta name="og:type" content="website" />
					<meta
						name="og:site_name"
						content={`${shopName} - Dolphin Robotic Pool Cleaner ${
							localityName ? `${localityName}, ` : ''
						}${countryFull}`}
					/>
					<html lang="en" />
					<meta name="theme-color" content="#58c8df" />
					<meta
						name="apple-mobile-web-app-status-bar-style"
						content="black-translucent"
					/>
					<meta name="application-name" content={`MyDolphin - ${shopName}`} />
					<meta name="msapplication-TileColor" content="#FFFFFF" />
				</Helmet>
				{/* HEADER */}
				<Nav
					url={shopWebsite}
					affiliate={shopAffiliate}
					logo={storeLogo ? storeLogo.file.url : ''}
					localStoreInRegion={localityName}
					openForm={openForm}
					ecommerceStoreLink={ecommerceStoreLink}
					storeCountry={storeCountry}
				/>
				<Banner
					staticMap={staticMapUrl.staticMapUrl}
					shopEmail={shopEmail}
					formattedPhone={formattedPhone}
					shopName={shopName}
					directMapsUrl={directMapsUrl}
					regionName={regionName}
				/>
				<div className="container">
					<div className="columns">
						{/* CONTENT */}
						<main className="main column is-three-quarters-desktop">
							<div className="content">
								<h1 className="title">{`${shopName} - Dolphin Robotic Pool Cleaner ${localityName}, ${countryFull}`}</h1>
								<h2 className="subtitle">{shopPageTitle}</h2>
								<p>
									{`${shopName} are your local Dolphin by Maytronics experts, buy and service Dolphin Robotic Pool Cleaners in ${
										localityName ? `${localityName}, ` : ''
									}${countryFull}.${
										formattedPhone ? ` Call us on ${formattedPhone}` : ''
									}`}
								</p>
								<div dangerouslySetInnerHTML={{ __html: pageText }} />
							</div>
							<EnquiryForm
								relativeUrl={relativeUrl}
								modalState={modalState}
								formName={formName}
								openForm={openForm}
								shopName={shopName}
								shopEmail={shopEmail}
								storeCountry={storeCountry}
							/>
							<div className="store-details is-hidden-desktop">
								<OpeningTimes
									isOpen={isOpen}
									openingTimes={[...openingTimes]}
									directMapsUrl={directMapsUrl}
								/>
								{facebookPageUrl ? <Facebook pageUrl={facebookPageUrl} /> : ''}
								<SquareMapLink
									directMapsUrl={directMapsUrl}
									staticMapImg={staticMapUrl.staticMapUrl}
									shopName={shopName}
								/>
							</div>
							<CallToAction
								heading="Get a FREE Dolphin Demo"
								text="Book now to experience an impressive tour of the features of our Dolphin Robotic Pool Cleaner, all conducted within your very own pool, for free, today."
								linkText="Enquire"
								openForm={openForm}
								ecommerceStoreLink={ecommerceStoreLink}
								storeCountry={storeCountry}
							/>
							<ProductStockist
								prettyStoreUrl={ecommerceStoreLink}
								storeCountry={storeCountry}
							/>
							<div className="feedback">
								<h3>
									<FontAwesomeIcon
										icon="check-circle"
										className="has-text-success"
									/>
									&nbsp;REVIEWS FROM&nbsp;
									<span>REAL CUSTOMERS</span> OF {shopName}
								</h3>
								<div className="columns">
									<RatingStars
										rating={starRating}
										shopName={shopName}
										amountRatings={amountRatings}
										placeId={placeId}
									/>
									{starRating >= 4 ? (
										<GoogleReviews
											reviews={[...userReviews]}
											shopName={shopName}
										/>
									) : (
										''
									)}
								</div>
							</div>
							<HorizontalDivide image={divideS300i} />
							<RepairAgent shopName={shopName} />
							{photoUrls.length !== 0 ? (
								<>
									<HorizontalDivide image={divideSwash} />
									<SlideShow slides={photoUrls} shopName={shopName} />
								</>
							) : (
								''
							)}
						</main>

						{/* RIGHT SIDEBAR */}
						<aside className="sidebar column is-one-quarter is-hidden-touch">
							<OpeningTimes
								isOpen={isOpen}
								openingTimes={[...openingTimes]}
								directMapsUrl={directMapsUrl}
							/>
							<SquareMapLink
								directMapsUrl={directMapsUrl}
								staticMapImg={staticMapUrl.staticMapUrl}
								shopName={shopName}
							/>
							{facebookPageUrl ? <Facebook pageUrl={facebookPageUrl} /> : ''}
							<ImgLightBox
								imgIndex={1}
								imgUrl={storePhoto ? storePhoto.file.url : ''}
								largeImgUrl={storePhoto ? storePhoto.file.url : ''}
								imgAlt={storePhotoImgAltText}
								imgTitle={storePhotoImgTitle}
								spanText={storePhotoImgSpanText}
								viewImage={this.viewImage}
								selectedImage={this.selectedImage}
								hideOnMobile="true"
							/>
							<ImgLightBox
								imgIndex={2}
								imgUrl={teamPhoto ? teamPhoto.file.url : ''}
								largeImgUrl={teamPhoto ? teamPhoto.file.url : ''}
								imgAlt={teamPhotoImgAltText}
								imgTitle={teamPhotoImgTitle}
								spanText={teamPhotoImgSpanText}
								viewImage={this.viewImage}
								selectedImage={this.selectedImage}
							/>
						</aside>
					</div>
				</div>
				{/* FOOTER */}
				<footer className="footer">
					<div className="inner" />
				</footer>
				<LightBox
					storePhoto={storePhoto ? storePhoto.file.url : ''}
					teamPhoto={teamPhoto ? teamPhoto.file.url : ''}
					shopName={shopName}
					isImageOpen={isImageOpen}
					viewImage={this.viewImage}
					images={photoUrls}
					imageIndex={imageIndex}
				/>
			</Layout>
		)
	}
}

StorePage.propTypes = {
	data: PropTypes.any,
}

export default StorePage

export const pageQuery = graphql`
	query getStoreById($relativeUrl: String = "/survey_results/1") {
		contentfulStore(relativeUrl: { eq: $relativeUrl }) {
			shopName
			storeCountry
			shopWebsite
			shopAffiliate
			relativeUrl
			placeId
			shopEmail
			shopPageTitle
			mainPageTextContent {
				childMarkdownRemark {
					html
				}
			}
			storePhoto {
				id
				file {
					url
				}
			}
			storePhotoImgAltText
			storePhotoImgSpanText
			storePhotoImgTitle
			teamPhoto {
				id
				file {
					url
				}
			}
			teamPhotoImgAltText
			teamPhotoImgTitle
			teamPhotoImgSpanText
			storeLogo {
				id
				file {
					url
				}
			}
			staticMapUrl {
				staticMapUrl
			}
			facebookPageUrl
		}
	}
`
