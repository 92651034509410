import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DolphinByMaytronics from '../../img/dolphin-by-maytronics.svg'
import m500 from '../../img/m500.jpg'

const Banner = props => {
	const {
		staticMap,
		shopEmail,
		formattedPhone,
		shopName,
		directMapsUrl,
		regionName,
	} = props
	return (
		<section className="Banner hero">
			<div className="gradient" />
			<div className="map" style={{ backgroundImage: `url(${m500})` }} />
			<svg
				width="100%"
				height="47px"
				viewBox="0 0 1440 47"
				className="wave"
				preserveAspectRatio="none"
			>
				<g id="Page-1" stroke="none" fill="none" fillRule="evenodd">
					<path
						d="M0,0.0577405639 C117.504588,18.44359 269.602477,22.1720696 456.293666,11.2431795 C562.076057,5.05068514 730.784198,0.911127653 885.297232,3.27366179 C1157.17617,7.43074321 1386.98062,21.3276838 1440,38.3891927 L1440,46.9388979 L0,46.9388979 L0,0.0577405639 Z"
						fill="#FFFFFF"
					/>
				</g>
			</svg>
			<div className="hero-body">
				<div className="container">
					<div className="field is-grouped is-hidden-dolphin-by-maytronics is-hidden-desktop">
						<p className="control">
							<span>Your Local</span>
						</p>
						<p className="control">
							<img src={DolphinByMaytronics} className="dolphin-logo" />
						</p>
						<p className="control">
							<span>Expert</span>
						</p>
					</div>
					<p className="is-dolphin-by-maytronics has-text-centered">
						<span>Your Local </span>
						<b>Dolphin by Maytronics </b>
						<span>Expert</span>
					</p>
					<div className="buttons" style={{ justifyContent: 'center' }}>
						{formattedPhone ? (
							<a className="button is-medium" href={`tel:${formattedPhone}`}>
								<span className="icon has-text-primary">
									<FontAwesomeIcon icon="phone" />
								</span>
								<span className="is-hidden-mobile">{formattedPhone}</span>
								<span className="is-hidden-tablet">Call Us</span>
							</a>
						) : (
							''
						)}
						{shopEmail ? (
							<a className="button is-medium" href={`mailto:${shopEmail}`}>
								<span className="icon has-text-primary">
									<FontAwesomeIcon icon="envelope" />
								</span>
								<span className="is-hidden-mobile">{shopEmail}</span>
								<span className="is-hidden-tablet">Email Us</span>
							</a>
						) : (
							''
						)}
					</div>
					<h1>{shopName}</h1>
					<h2>
						{regionName ? (
							<a
								target="_blank"
								rel="nofollow noopener noreferrer"
								href={directMapsUrl}
							>
								<span>Pool shop at</span>
								<span className="icon has-text-primary">
									<FontAwesomeIcon icon="map-marker" />
								</span>
								<span>{regionName ? regionName : 'No Address'}</span>
							</a>
						) : (
							''
						)}
					</h2>
				</div>
			</div>
		</section>
	)
}

Banner.propTypes = {
	staticMap: PropTypes.string,
	shopEmail: PropTypes.string,
	formattedPhone: PropTypes.string,
	shopName: PropTypes.string,
	directMapsUrl: PropTypes.string,
	regionName: PropTypes.string,
}

export default Banner
