import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LightBox = props => {
	const {
		viewImage,
		images,
		imageIndex,
		shopName,
		isImageOpen,
		storePhoto,
		teamPhoto,
	} = props

	let slideIndex = 1
	slideIndex = imageIndex

	function showSlide(n) {
		let i
		const x = document.getElementsByClassName('slide')
		if (n > x.length) {
			slideIndex = 1
		}
		if (n < 1) {
			slideIndex = x.length
		}
		for (i = 1; i < x.length; i++) {
			x[i].style.display = 'none'
		}
		x[slideIndex].style.display = 'block'
		console.log(imageIndex)
	}

	function navSlide(n) {
		showSlide((slideIndex += n))
	}

	if (imageIndex) {
		showSlide(slideIndex)
	}

	return (
		<div
			className={isImageOpen ? `is-active modal` : `modal`}
			id="google-images"
		>
			<div className="modal-background" onClick={() => viewImage()} />
			<div className="modal-content">
				<div className="box">
					<div className="content">
						<div className={`slides on-slide-${imageIndex}`}>
							<div className="slide" id="slide-0" />
							<div className="slide" id="slide-1">
								<img
									src={storePhoto}
									alt={`${shopName} Store`}
									title={`${shopName} Store`}
								/>
								<label className="label">
									<span className="icon">
										<FontAwesomeIcon icon="camera" />
									</span>
									<span>{`${shopName} Store`}</span>
								</label>
							</div>
							<div className="slide" id="slide-2">
								<img
									src={teamPhoto}
									alt={`${shopName} Team`}
									title={`${shopName} Team`}
								/>
								<label className="label">
									<span className="icon">
										<FontAwesomeIcon icon="camera" />
									</span>
									<span>{`${shopName} Team`}</span>
								</label>
							</div>
							{images.map((photo, index) => (
								<div key={index} id={`slide-${index + 3}`} className="slide">
									<img
										src={photo}
										alt={`${shopName} (${index + 1})`}
										title={`${shopName} (${index + 1})`}
									/>
									<label className="label">
										<span className="icon">
											<FontAwesomeIcon icon="camera" />
										</span>
										<span>{`${shopName} (${index + 1})`}</span>
									</label>
								</div>
							))}
							<button className="button" onClick={() => navSlide(-1)}>
								<FontAwesomeIcon icon="chevron-left" />
							</button>
							<button className="button" onClick={() => navSlide(+1)}>
								<FontAwesomeIcon icon="chevron-right" />
							</button>
						</div>
					</div>
				</div>
			</div>
			<button
				className="modal-close is-large"
				aria-label="close"
				onClick={() => viewImage()}
			/>
		</div>
	)
}

LightBox.propTypes = {
	viewImage: PropTypes.any,
	images: PropTypes.any,
	imageIndex: PropTypes.any,
	shopName: PropTypes.any,
	isImageOpen: PropTypes.bool,
	storePhoto: PropTypes.any,
	teamPhoto: PropTypes.any,
}

export default LightBox
