export const models = {
	nz: [
		{ value: 'M500', label: 'M500' },
		{ value: 'M400', label: 'M400' },
		{ value: 'M3', label: 'M3' },
		{ value: 'S200', label: 'S200' },
		{ value: 'S100', label: 'S100' },
		{ value: 'S50', label: 'S50' },
	],
	za: [
		{ value: 'M500', label: 'M500' },
		{ value: 'M5 Liberty', label: 'M5 Liberty' },
		{ value: 'E30 Plus', label: 'E30 Plus' },
		{ value: 'M3', label: 'M3' },
	],
	au: [
		{ value: 'M500', label: 'M500' },
		{ value: 'M400', label: 'M400' },
		{ value: 'S200', label: 'S200' },
		{ value: 'S300i', label: 'S300i' },
		{ value: 'S50', label: 'S50' },
	],
}
export const regions = {
	nz: [
		{ value: 'North Island', label: 'North Island' },
		{ value: 'South Island', label: 'South Island' },
		{ value: 'Outside New Zealand', label: 'Outside New Zealand' },
	],
	za: [
		{ value: 'The Eastern Cape', label: 'The Eastern Cape' },
		{ value: 'The Free State', label: 'The Free State' },
		{ value: 'Gauteng', label: 'Gauteng' },
		{ value: 'KwaZulu-Natal', label: 'KwaZulu-Natal' },
		{ value: 'Limpopo', label: 'Limpopo' },
		{ value: 'Mpumalanga', label: 'Mpumalanga' },
		{ value: 'The Northern Cape', label: 'The Northern Cape' },
		{ value: 'North West', label: 'North West' },
		{ value: 'The Western Cape', label: 'The Western Cape' },
	],
	au: [
		{ value: 'New South Wales', label: 'New South Wales' },
		{ value: 'Victoria', label: 'Victoria' },
		{ value: 'Queensland', label: 'Queensland' },
		{ value: 'South Australia', label: 'South Australia' },
		{ value: 'Western Australia', label: 'Western Australia' },
		{ value: 'Tasmainia', label: 'Tasmainia' },
		{ value: 'Northern Territory', label: 'Northern Territory' },
		{
			value: 'Australian Capital Territory',
			label: 'Australian Capital Territory',
		},
	],
}
export const conditions = {
	nz: [
		{ value: 'Dirty', label: 'Dirty' },
		{ value: 'Clean', label: 'Clean' },
		{ value: 'Not Sure', label: 'Not Sure' },
	],
	za: [
		{ value: 'Dirty', label: 'Dirty' },
		{ value: 'Clean', label: 'Clean' },
		{ value: 'Not Sure', label: 'Not Sure' },
	],
	au: [
		{ value: 'Dirty', label: 'Dirty' },
		{ value: 'Clean', label: 'Clean' },
		{ value: 'Not Sure', label: 'Not Sure' },
	],
}
