import React from 'react'
import PropTypes from 'prop-types'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import dolphinApproved from '../../img/Authorised_Dealer.svg'

const RatingStars = props => {
	let isHighRatedDealer = false
	const rating = props.rating.toString()
	const leaveReviewLink = `https://search.google.com/local/writereview?placeid=${
		props.placeId
	}`

	// Rating format is "[0-9].[0-9]" e.g. 4.2
	const isValidRating = rating.match(/^\d{1}(\.\d{1})?$/g)

	// stores array of star images
	// let stars

	if (isValidRating && rating >= 4) {
		isHighRatedDealer = true
		// round up/down the rating
		// const beforeDecimal = rating.charAt(0)
		// const afterDecimal = rating.charAt(2)
		// const ratingRoundedToPoint5 = Math.round(afterDecimal / 5) * 5
		// const ratingEndsIn5 = rating % 1 > 0

		// const starImg = <FontAwesomeIcon icon="star" />
		// const halfStarImg = <FontAwesomeIcon icon="star-half" />

		// create array of star images
		// stars = Array(Number(beforeDecimal)).fill(starImg)
		// ratingEndsIn5 ? stars.push(halfStarImg) : ''
	}
	const topRatedDealerStamp = () => {
		if (isHighRatedDealer) {
			return (
				<div className="rating column is-narrow">
					<div className="approved">
						<img src={dolphinApproved} />
						{/* <div className="stars is-size-4 has-text-warning has-text-centered">
							{stars}
						</div> */}
					</div>
					<div className="google-rating">
						Google Rating avg.&nbsp;
						<span className="out-of-five tag">
							<span>{props.rating}</span>
							/5
						</span>
					</div>
				</div>
			)
		} else {
			return (
				<div className="rating" style={{ margin: '0 auto' }}>
					<div className="google-share">
						<p>
							Have you been to {props.shopName}?
							<a rel="nofollow" href={leaveReviewLink}>
								Tell us about your own experience!
							</a>
						</p>
					</div>
				</div>
			)
		}
	}
	return topRatedDealerStamp()
}

RatingStars.propTypes = {
	shopName: PropTypes.string,
	rating: PropTypes.any,
	placeId: PropTypes.string,
}

export default RatingStars
