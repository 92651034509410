import React from 'react'
import PropTypes from 'prop-types'
import FacebookProvider, { Page } from 'react-facebook'

const Facebook = props => {
	const { pageUrl } = props
	return (
		<div className="Facebook">
			<FacebookProvider appId="296350630946797">
				<Page href={pageUrl} />
			</FacebookProvider>
		</div>
	)
}

Facebook.propTypes = {
	pageUrl: PropTypes.string,
}

export default Facebook
